import { useCart } from "./hooks/useCart";
import useLocations from "./hooks/useLocations";
import React, { useState } from "react";
import PanelLayout from "./common/PanelLayout";
import { useForm } from "react-hook-form";
import LinkButton from "./common/LinkButton";

const Checkout = function () {
  const { itemCount, cartItems, clearCart } = useCart();
  const { register, handleSubmit } = useForm();
  const [submitState, setSubmitState] = useState(null);

  const { locations } = useLocations();
  const schools =
    !!locations &&
    locations.filter(
      (location) =>
        !["Warehouse", "Regift", "DAMAGED GOODS"].includes(
          location["Location Name"]
        )
    );

  const onSubmit = (data, e) => {
    e.preventDefault();
    setSubmitState("loading");

    const postData = { ...data, cart: cartItems };

    fetch("/api/checkout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postData),
    })
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.error) || response.status;
          return Promise.reject(error);
        }

        clearCart();
        setSubmitState("success");
      })
      .catch((error) => {
        setSubmitState("error");
        console.error("There was an error!", error);
      });
  };
  const onError = (errors) => console.log(errors);

  return (
    <div className="">
      <PanelLayout
        mainComponent={
          <div className="p-3 md:p-5 max-w-lg md:mx-auto">
            {submitState === "loading" && (
              <div className="flex py-8 items-center justify-center flex-col space-y-8">
                <h4 className="text-center text-xl font-bold text-sssGray">
                  ☁️
                  <br />
                  Submitting
                </h4>
              </div>
            )}
            {submitState === "error" && (
              <div className="flex py-8 items-center justify-center flex-col space-y-8">
                <h4 className="text-center text-xl font-bold text-sssGray">
                  🤨
                  <br />
                  Checkout error
                </h4>
                <div className="w-1/2">
                  <LinkButton to="/cart">Try again</LinkButton>
                </div>
              </div>
            )}
            {submitState === "success" && (
              <div className="flex py-8 items-center justify-center flex-col space-y-8">
                <h4 className="text-center text-xl font-bold text-green-600">
                  👍
                  <br />
                  Checkout complete
                </h4>
                <div className="w-1/2">
                  <LinkButton to="/">Start New Cart</LinkButton>
                </div>
              </div>
            )}
            {!submitState && (
              <>
                <h4 className="text-xl font-bold mb-4">
                  Check Out {itemCount} Items
                </h4>
                <form className="" onSubmit={handleSubmit(onSubmit, onError)}>
                  <div className="sss-input">
                    <label>School</label>
                    <select {...register("locationName")} required>
                      <option value="">Select</option>
                      {!!schools &&
                        schools.map((location) => (
                          <option
                            key={`${location["Location Name"]}-option`}
                            value={location["Location Name"]}
                          >
                            {location["Location Name"]}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="md:flex justify-between md:space-x-4">
                    <div className="sss-input flex-1">
                      <label>Student's Initials</label>
                      <input
                        {...register("studentInitials")}
                        maxlength="2"
                        required
                        placeholder="First & Last"
                      />
                    </div>
                    <div className="sss-input flex-1">
                      <label>Student ID Number</label>
                      <input {...register("studentId")} required />
                    </div>
                    <div className="sss-input w-1/5">
                      <label>Grade</label>
                      <select {...register("studentGrade")}>
                        <option value="">Select</option>
                        <option value="6">6th</option>
                        <option value="7">7th</option>
                        <option value="8">8th</option>
                        <option value="9">9th</option>
                        <option value="10">10th</option>
                        <option value="11">11th</option>
                        <option value="12">12th</option>
                      </select>
                    </div>
                  </div>

                  <div className="sss-input flex-1">
                    <label>Name of Person Checking Out</label>
                    <input {...register("checkoutName")} required />
                  </div>

                  {/* 
              <div className="sss-input">
                <label>Notes/Comments</label>
                <textarea {...register("comments")} />
              </div> */}

                  <button
                    type="submit"
                    disabled={!!submitState}
                    className={`mt-8 inline-block text-center w-1/3 font-medium ${
                      !submitState
                        ? "hover:opacity-90"
                        : "opacity-25 cursor-default"
                    } rounded-lg px-4 py-2 bg-sssOrange text-white`}
                  >
                    Send
                  </button>
                </form>
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

export default Checkout;
