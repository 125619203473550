import { Link } from "react-router-dom";

const LinkButton = function ({ to, children, disabled }) {
  return (
    <Link
      to={!disabled && to}
      className={`inline-block text-center w-full font-medium ${
        !disabled ? "hover:opacity-90" : "opacity-25 cursor-default"
      } rounded-lg px-4 py-2 bg-sssOrange text-white`}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
