import useProducts from "./hooks/useProducts";
import Product from "./Product";
import React, { useState } from "react";
import PanelLayout from "./common/PanelLayout";

const Products = function () {
  const { products, categories, error } = useProducts();

  const [categoryFilter, setCategoryFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");

  const displayedProducts = !!products
    ? products.filter((product) => {
        return (
          (categoryFilter === null || categoryFilter === product.Category) &&
          product.Name.toLowerCase().includes(textFilter.toLowerCase())
        );
      })
    : [];

  return (
    <PanelLayout
      scrollToTopIfChanged={displayedProducts}
      mainComponent={
        <>
          {!!error && (
            <div className="p-8 font-medium text-center text-red-700">
              Error loading products
            </div>
          )}
          {!!products ? (
            <ul>
              {displayedProducts.map((product, i) => (
                <Product
                  key={`products-list-${i}-${product["Product Id"]}`}
                  data={product}
                />
              ))}
            </ul>
          ) : (
            <div className="p-8 text-center text-gray-500">Loading...</div>
          )}
        </>
      }
      asideComponent={
        <>
          <div className="mb-4 lg:pb-4 lg:border-b">
            <input
              placeholder="Search"
              onChange={(e) => setTextFilter(e.target.value)}
              className={`${
                !!textFilter ? "border-sssOrange" : ""
              } rounded-md px-2 md:px-4 py-1 lg:py-3 border w-full`}
            />
          </div>

          <div className="mb-4 grid grid-cols-4 md:grid-cols-5 lg:grid-cols-2 gap-1 md:gap-2 text-sm md:text-md leading-tight w-full justify-center items-stretch h-16 md:h-auto">
            <button
              onClick={() => setCategoryFilter(null)}
              className={`${
                categoryFilter == null
                  ? "bg-sssOrange text-white border-sssOrange"
                  : ""
              } font-medium rounded-lg px-2 md:px-4 py-2 border md:py-3 lg:py-6 col-span-4 md:col-span-1 lg:col-span-2 hover:border-sssOrange`}
            >
              All Categories
            </button>

            {!!categories &&
              categories
                .filter((category) => !!category)
                .map((category) => (
                  <button
                    key={`category-button-${category}`}
                    onClick={(e) => {
                      setCategoryFilter(category);
                      e.preventDefault();
                    }}
                    className={`${
                      categoryFilter === category
                        ? "bg-sssOrange text-white border-sssOrange"
                        : ""
                    } font-medium rounded-lg px-2 md:px-4 py-1 border md:py-3 lg:py-6 hover:border-sssOrange`}
                  >
                    {category}
                  </button>
                ))}
          </div>
        </>
      }
    />
  );
};

export default Products;
