import triangle from "./logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { useCart } from "./hooks/useCart";

const NavBarLink = function ({ to, children }) {
  return (
    <NavLink
      exact
      activeClassName="bg-sssOrange bg-opacity-20"
      className={`flex items-center space-x-2 relative p-2 hover:bg-opacity-30 hover:bg-sssOrange`}
      to={to}
    >
      {children}
    </NavLink>
  );
};

const Nav = function ({ title }) {
  const { itemCount } = useCart();

  const { pathname } = useLocation();
  title =
    title ||
    {
      "/": "Products",
      "/cart": "Cart",
      "/checkout": "Checkout",
    }[pathname];

  return (
    <header
      style={{ touchAction: "none" }}
      className="border-b-4 lg:border-b-2 flex space-x-2 items-center bg-white h-14 md:h-12"
    >
      <span className="pl-4 lg:pl-2">
        <img
          src={triangle}
          className="w-5 h-auto"
          alt="Student Success Stores"
        />
      </span>

      <div className="font-bold">{title || "Student Success Stores"}</div>

      <nav className="flex-grow flex space-x-0 text-4xl md:text-lg justify-end">
        <NavBarLink to="/">🔎</NavBarLink>
        <NavBarLink to="/cart">
          <span>🛒</span>
          <span
            className={`inline-block py-1 text-center w-8 -mt-1 text-xs ${
              itemCount > 0 ? "bg-sssGray" : "bg-gray-300"
            } rounded-full text-white`}
          >
            {itemCount || 0}
          </span>
        </NavBarLink>
      </nav>
    </header>
  );
};

export default Nav;
