// NOTE: Based on https://github.com/AlexSegen/react-shopping-cart/blob/master/src/contexts/CartReducer.js

const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

export const sumItems = (cartItems) => {
  Storage(cartItems);
  let itemCount = cartItems.reduce(
    (total, product) => total + product.quantity,
    0
  );
  return { itemCount };
};

export const CartReducer = (state, action) => {
  let newItems = state.cartItems.slice();

  // TODO, this is kind of a mess, so clean up

  switch (action.type) {
    case "ADD_ITEM":
      if (!state.cartItems.find((item) => item.id === action.payload.id)) {
        newItems.push({
          ...action.payload,
          quantity: 1,
        });
      }

      return {
        ...state,
        ...sumItems(newItems),
        cartItems: [...newItems],
      };
    case "REMOVE_ITEM":
      newItems = newItems.filter((item) => item.id !== action.payload.id);

      return {
        ...state,
        ...sumItems(newItems),
        cartItems: [...newItems],
      };
    case "INCREASE":
      newItems = newItems.map((item) => {
        if (item.id === action.payload.id) {
          const newItem = { ...item };
          newItem.quantity++;
          return newItem;
        } else {
          return item;
        }
      });

      return {
        ...state,
        ...sumItems(newItems),
        cartItems: [...newItems],
      };
    case "DECREASE":
      newItems = newItems.map((item) => {
        if (item.id === action.payload.id) {
          const newItem = { ...item };
          newItem.quantity--;
          newItem.quantity = Math.max(newItem.quantity, 0);
          return newItem;
        } else {
          return item;
        }
      });

      return {
        ...state,
        ...sumItems(newItems),
        cartItems: [...newItems],
      };
    case "SET":
      newItems = newItems.map((item) => {
        if (item.id === action.payload.id) {
          const newItem = { ...item };
          newItem.quantity = action.payload.quantity;
          newItem.quantity = Math.max(newItem.quantity, 0);
          return newItem;
        } else {
          return item;
        }
      });
      
      return {
        ...state,
        ...sumItems(newItems),
        cartItems: [...newItems],
      };
    case "CHECKOUT":
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([]),
      };
    case "CLEAR":
      return {
        cartItems: [],
        ...sumItems([]),
      };
    default:
      return state;
  }
};
