import Nav from "./Nav";
import Cart from "./Cart";
import Products from "./Products";
import Checkout from "./Checkout";
import CartContextProvider from "./contexts/CartContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <CartContextProvider>
        <div className="text-sssGray bg-gray-50">
          <header className="w-full fixed top-0">
            <Nav />
          </header>
          <main className="md:mx-auto">
            <Switch>
              <Route exact path="/">
                <Products />
              </Route>
              <Route path="/cart">
                <Cart />
              </Route>
              <Route path="/checkout">
                <Checkout />
              </Route>
            </Switch>
          </main>
        </div>
      </CartContextProvider>
    </Router>
  );
}

export default App;
