import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { useRef, useEffect } from "react";

const PanelLayout = function ({
  asideComponent,
  mainComponent,
  scrollToTopIfChanged,
}) {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollTarget = scrollRef.current;
    disableBodyScroll(scrollTarget);
    return () => {
      enableBodyScroll(scrollTarget);
    };
  });

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [scrollToTopIfChanged]);

  return (
    <div className="absolute top-14 md:top-12 bottom-0 left-0 w-full flex justify-between flex-col lg:flex-row">
      <div
        ref={scrollRef}
        className="lg:flex-grow overflow-y-scroll overscroll-contain"
      >
        {mainComponent}
      </div>
      {!!asideComponent && (
        <div
          style={{ touchAction: "none" }}
          className="overscroll-none bg-white w-full p-3 md:p-5 border-t-4 lg:border-l lg:border-t-0 h-auto lg:h-full lg:flex-none lg:w-72"
        >
          {asideComponent}
        </div>
      )}
    </div>
  );
};

export default PanelLayout;
