import useSWR from "swr";
import fetcher from "../fetcher.js";

const useLocations = () => {
  const { data, error } = useSWR("/api/fetch/locations", fetcher, {
    focusThrottleInterval: 10 * 60 * 1000, // Max fetch every 10 minutes
  });

  const locations = !!data ? data.locations : null;

  return {
    locations: locations,
    error: error,
  };
};

export default useLocations;
