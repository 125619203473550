import LinkButton from "./common/LinkButton";
import useProducts from "./hooks/useProducts";
import { useCart } from "./hooks/useCart";
import Product from "./Product";
import React from "react";
import { Link } from "react-router-dom";

import PanelLayout from "./common/PanelLayout";

const Cart = function () {
  const { products, error } = useProducts();
  const { cartItems, itemCount } = useCart();

  const displayedProducts = !!products
    ? products.filter(
        (product) =>
          !!cartItems.find((item) => item.id === product["Product Id"])
      )
    : [];

  if (error)
    return <div className="p-4 text-red-700">Error loading products</div>;

  return (
    <div className="">
      <PanelLayout
        mainComponent={
          <>
            {itemCount === 0 && (
              <div className="p-8 text-center flex flex-col space-y-8">
                <h2 className="text-gray-400 text-xl font-medium">
                  Nothing in cart
                </h2>

                <div>
                  <Link className="p-4 text-sssOrange" to="/">
                    Search Products
                  </Link>
                </div>
              </div>
            )}

            {!!displayedProducts ? (
              <ul>
                {displayedProducts.map((product, i) => (
                  <Product
                    key={`products-list-${i}-${product["Product Id"]}`}
                    data={product}
                  />
                ))}
              </ul>
            ) : (
              <div className="p-8 text-center text-gray-500">Loading...</div>
            )}
          </>
        }
        asideComponent={
          <div className="flex lg:flex-col space-x-3 lg:space-x-0 lg:space-y-3">
            <LinkButton disabled={itemCount === 0} to="/checkout">
              Checkout
            </LinkButton>
          </div>
        }
      />
    </div>
  );
};

export default Cart;
