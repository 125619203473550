import useSWR from "swr";
import fetcher from "../fetcher.js";

const useProducts = () => {
  const { data, error } = useSWR("/api/fetch/products", fetcher, {
    focusThrottleInterval: 5 * 60 * 1000, // Max fetch every 5 minutes
  });

  const products = !!data ? data.products : null;
  const categories = !!products
    ? [...new Set(products.map((item) => item.Category))]
    : [];

  return {
    products: products,
    categories: categories,
    error: error,
  };
};

export default useProducts;
