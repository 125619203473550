import { useCart } from "./hooks/useCart";

const Product = function ({ data }) {
  const { cartItems, addProduct, removeProduct, increase, decrease, setQuantity } =
    useCart();

  const cartRecord = cartItems.find((item) => item.id === data["Product Id"]);

  return (
    <li
      className={`border-b p-3 md:p-5 list-none even:bg-gray-100 ${
        !cartRecord && "flex"
      } lg:flex lg:space-x-4 items-start justify-between`}
      key={`product-${data["Product Id"]}`}
    >
      <div className="">
        <p className="text-sm text-blue-400">{data.Category}</p>
        <p className="font-bold">{data.Name}</p>
        {data["Item notes"] && (
          <p className="text-sm text-gray-600">{data["Item notes"]}</p>
        )}
      </div>

      <div className="flex lg:w-1/3 flex-none justify-between space-x-2 mt-2">
        <div className="flex items-center space-x-2">
          {cartRecord && (
            <>
              <button
                disabled={cartRecord.quantity === 1}
                className={`inline-block font-medium ${
                  cartRecord.quantity === 1 ? "opacity-25" : ""
                } border border-sssOrange rounded-lg px-4 py-1 font-bold text-sssOrange`}
                onClick={() => decrease({ id: data["Product Id"] })}
              >
                -
              </button>
              <input
                type="number"
                value={cartRecord.quantity}
                pattern="[0-9]*"
                onChange={(e) => setQuantity({ id: data["Product Id"], quantity: e.target.value } ) }
                className="w-12 inline-block font-medium hover:opacity-90 font-medium rounded-lg text-center py-2 bg-white"
              />

              <button
                className="inline-block font-medium hover:opacity-90 border border-sssOrange rounded-lg px-4 py-1 font-bold text-sssOrange"
                onClick={() => increase({ id: data["Product Id"] })}
              >
                +
              </button>
              
              <span className="text-sssOrange opacity-90 text-sm">{ data["Unit Quantity Label"] }</span>
            </>
          )}
        </div>
        <div className="">
          {!cartRecord ? (
            <button
              className="inline-block font-medium hover:opacity-90 border border-transparent rounded-lg px-3 py-2 text-sm text-sssOrange"
              onClick={() => addProduct({ id: data["Product Id"] })}
            >
              Add
            </button>
          ) : (
            <button
              className="inline-block font-medium hover:opacity-90 border border-dashed border-sssOrange rounded-lg px-3 py-2 text-sm text-sssOrange"
              onClick={() => removeProduct({ id: data["Product Id"] })}
            >
              Remove
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default Product;
